
let data = [
    {
        images: '01',
        title: 'Mario Alvarez',
        designation: 'Supervisor',
        socialNetwork: [
        ]
    },
    {
        images: '02',
        title: 'Lilian Yanes',
        designation: 'Asesora de Ventas',
        socialNetwork: [
        ]
    },
    {
        images: '03',
        title: 'Ana Guerra',
        designation: 'Asesora de Ventas',
        socialNetwork: [
        ]
    },
    {
        images: '04',
        title: 'Eymi Tornoe',
        designation: 'Asesora de Ventas',
        socialNetwork: [
        ]
    }
];

export default data;