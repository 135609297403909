import React, { Component } from "react";

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
                <ul className="brand-style-2">
                    <li>
                        <img src="/assets/images/brand/brand-07.png" alt="GAZPROMNEFT"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-08.png" alt="WANDA"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-09.png" alt="GOODYEAR"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-11.png" alt="DUNLOP"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-12.png" alt="LUBERFINER"/>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;