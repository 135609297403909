import React, { Component , Fragment } from "react";
import ScrollToTop from 'react-scroll-up';
import Slider from "react-slick";
import { slideSlick } from "../page-demo/script";
import Header from "../component/header/HeaderFive";
import FooterTwo from "../component/footer/FooterTwo";
import CallAction from "../elements/callaction/CallAction";
import Team from "../blocks/team/TeamTwo";
import Helmet from "../component/common/Helmet";
import { FiStar , FiAward , FiUsers ,FiChevronUp } from "react-icons/fi";
import CounterOne from "../elements/counters/CounterOne";
import BrandOne from "../elements/Brand";
import BrandTwo from "../elements/BrandTwo";


const SlideList = [
    {
        textPosition: 'text-left',
        bgImage: 'bg_image--31',
        category: '',
        title: 'SERVITECA',
        description: 'Lo mejor para tu automóvil',
        buttonText: 'Contactanos',
        buttonLink: 'mailto:supervisor@serviteca.net'
    }
]

const ServiceListOne = [
    {
        icon: <FiAward />,
        title: 'Mecanicos Cualificados',
        description: ''
    },
    {
        icon: <FiStar />,
        title: 'Productos y Servicios de Calidad',
        description: ''
    },
    {
        icon: <FiUsers />,
        title: 'Personal Amigable, Honesto, y Confiable',
        description: ''
    },
]

const starndardService = [
    {
        image: '',
        title: 'ALINEACION',
        description: '',
    },
    {
        image: '',
        title: 'BALANCEO',
        description: '',
    },
    {
        image: '',
        title: 'MECANICA GENERAL',
        description: '',
    },
    {
        image: '',
        title: 'MECANICA DE TREN DELANTERO',
        description: '',
    },
    {
        image: '',
        title: 'SERVICIO MENOR Y MAYOR',
        description: '',
    },
    {
        image: '',
        title: 'FRENOS',
        description: '',
    },
    {
        image: '',
        title: 'LAVADO',
        description: '',
    },
    {
        image: '',
        title: 'LUBRICACION',
        description: '',
    }
]

class CorporateBusiness extends Component{
    constructor () {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){

        return(
            <Fragment> 
                <Helmet pageTitle="SERVITECA" />

                {/* Start Header Area  */}
                <Header headerPosition="header--static logoresize" logo="all-dark" color="color-black"/>
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <div className="slider-activation">
                        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                            {SlideList.map((value , index) => (
                                <div className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index}>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ? <h1 className="title">{value.title}</h1> : ''}
                                                    {value.description ? <p className="description">{value.description}</p> : ''}
                                                    {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-solid" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
                {/* End Slider Area   */}
                
                {/* Start Service Area */}
                <div className="service-area ptb--30 bg_color--1">
                    <div className="container">
                        <div className="row service-one-wrapper">
                            {ServiceListOne.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* End Service Area */}


                {/* Start Featured Service Area  */}
                <div className="rn-featured-service-area pt--90 pb--120 bg_color--5">
                    <div className="container">
                        <div className="row">

                            {/* Start Single Service  */}
                            <div className="col-lg-3 col-md-6 col-12 mt--30">
                                <div className="section-title">
                                    <h2 className="title">Servicios</h2>
                                    <br/>
                                </div>
                            </div>
                            {/* End Single Service  */}

                            {/* Start Single Service  */}
                            <div className="col-lg-9">
                                <div className="row">
                                    {starndardService.map((value , index) => (
                                        <div className="col-lg-4 col-md-4 mt--30" key={index}>
                                            <div className="standard-service">
                                                <div className="content">
                                                    <h3>{value.title}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                
                            </div>
                            {/* End Single Service  */}

                        </div>
                    </div>                
                </div>
                {/* End Featured Service Area  */}

                {/* Start Counterup Area */}
                <div className="counterup-area pb--80 pt--40 bg_image bg_image--17 theme-text-white" data-black-overlay="7">
                    <div className="container">
                        <CounterOne />
                    </div>
                </div>
                {/* End Counterup Area */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-6">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-4.png" alt="About Images"/>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">Acerca de Nosotros</h2>
                                        <p>En Serviteca nos esmeramos para darte el mejor servicio y poner a tu disposición los mejores productos para tu vehículo!</p>
                                        <p>Nuestra misión es mantener tu vehículo como nuevo, con producto de la mas alta calidad, y mecánicos entrenados, brindándote seguridad, tranquilidad y comodidad en cada uno de tus viajes en él.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                {/* Start Team Area  */}
                <div className="rn-team-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                                    <h2 className="title">Equipo de Profesionales</h2>
                                    <p>Nuestro amigable y calificado personal esta a su disposicion para apoyarle con cualquier consulta automovilistica.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <Team column="col-lg-3 col-md-6 col-sm-6 col-12 mt--30" teamStyle="" item="4" />
                        </div>
                        <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                                    <br/>
                                    <br/>
                                    <h4>Serviteca San Benito</h4>
                                    <h5>Barrio Valle Nuevo, San Benito, Guatemala</h5>
                                    <h5>+502 7926-3105</h5>
                                    <br/>
                                    <h4>Serviteca Santa Elena</h4>
                                    <h5>Frente a CUDEP- USAC, Flores, Guatemala</h5>
                                    <h5>+502 7926-0311</h5>
                                </div>

                    </div>
                </div>
                {/* End Team Area  */}

                {/* Start Brand Area  */}
                <div className="rn-brand-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                        <p>EMPRESAS QUE NOS CONFIAN EL CUIDADO DE SU FLOTILLA LOCAL:</p>
                        <br/>
                            <div className="col-lg-12">
                                <BrandOne branstyle="branstyle--2" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area  */}

                <div className="rn-brand-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                        <p>MARCAS DE PRODUCTO QUE VENDEMOS:</p>
                        <br/>
                            <div className="col-lg-12">
                                <BrandTwo branstyle="branstyle--2" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Start call To Action  */}
                <CallAction />
                {/* End call To Action  */}

                {/* Start Footer Style  */}
                <FooterTwo />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}
export default CorporateBusiness;